import React from "react"
import { Link } from "gatsby"
import puCoating from "../images/dnd/pu-coating.png"
import stylamIcon from "../images/dnd/stylam-icon.png"

function PULacquer() {
  const lcUrl = "/products/lacquer-coating/"

  return (
    <div className="uk-container uk-margin-large">
      <div
        className="uk-card uk-card-default uk-padding-small uk-grid-collapse"
        data-uk-grid
      >
        <div className="uk-width-expand@s uk-width-1-1">
          <div class="uk-inline">
            <img src={puCoating} className="uk-width-expand" alt="" />
            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
              <div className="uk-flex uk-flex-between">
                <h2 className="uk-h2 uk-padding-remove">PU+ LACQUER COATING</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-3@s uk-width-1-1 uk-padding-small uk-align-center uk-align-middle">
          <div>
            <div>
              <Link
                to={`${lcUrl}touch-me-laminate`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Touch Me Anti-Finger Print Laminate
              </Link>
            </div>
            <div>
              <Link
                to={`${lcUrl}glosspro-laminate`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                GlossPro+ Highly Gloss Laminate
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PULacquer
