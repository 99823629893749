import React from "react"
import { Link } from "gatsby"
import StandardLayout from "../layouts/standard"
import PageCover from "../components/page-cover"
import DecorativeLaminates from "../components/DecorativeLaminates"
import SpecialSurfaces from "../components/SpecialSurfaces"
import SolidSurface from "../components/SolidSurface"
import PULacquer from "../components/PULacquer"
import CompactLaminates from "../components/CompactLaminates"
import coverImage from "../images/dnd/home-cover_1.jpg"

function homeOwners() {
  return (
    <StandardLayout>
      <div>
        <PageCover
          coverImage={coverImage}
          title="Home Owners"
          description=""
        ></PageCover>
      </div>

      <section className="uk-section">
        <div className="uk-container uk-margin">
          <div>
            <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
              LAMINATES
            </h1>
          </div>
        </div>

        <DecorativeLaminates />

        <SpecialSurfaces />

        <SolidSurface />

        <div className="uk-container uk-margin">
          <div>
            <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
              PRELAM BOARDS
            </h1>
          </div>
        </div>
        <PULacquer />

        <CompactLaminates />

        {/* <div className="uk-container uk-margin">
          <div>
            <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
              TOP TRENDING
            </h1>
          </div>
        </div> */}
      </section>
    </StandardLayout>
  )
}

export default homeOwners
