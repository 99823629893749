import React from "react"
import decorativeLaminates from "../images/dnd/dl.png"

function DecorativeLaminates() {
  return (
    <div className="uk-container uk-margin-large">
      <div
        className="uk-card uk-card-default uk-padding-small uk-grid-collapse"
        data-uk-grid
      >
        <div className="uk-width-expand@s uk-width-1-1">
          <div class="uk-inline">
            <img src={decorativeLaminates} className="uk-width-expand" alt="" />
            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
              <div className="uk-flex uk-flex-between">
                <h2 className="uk-h2 uk-padding-remove">
                  Decorative laminates
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DecorativeLaminates
