import React from "react"
// import coverImage from "../images/about/our-mission/our-mission-cover.jpg"

export default function PageCover({ title, description, coverImage }) {
  return (
    <div
      class="uk-cover-container"
      data-uk-height-viewport="offset-bottom: 50;"
    >
      <img src={coverImage} alt="" data-uk-cover />
      <div class="uk-overlay-primary uk-position-cover"></div>
      <div class="uk-overlay uk-position-center-left uk-light">
        <div className="uk-container">
          <h1 className="uk-h1 uk-text-uppercase">{title}</h1>
          <div className="uk-width-large">{description}</div>
        </div>
      </div>
    </div>
  )
}
