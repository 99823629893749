import React from "react"
import specialSurfaces from "../images/dnd/special-surfaces.png"
import { Link } from "gatsby"
import stylamIcon from "../images/dnd/stylam-icon.png"

function SpecialSurfaces() {
  const ssUrl = "/products/special-surfaces/"
  return (
    <div className="uk-container uk-margin-large">
      <div
        className="uk-card uk-card-default uk-padding-small uk-grid-collapse"
        data-uk-grid
      >
        <div className="uk-width-expand@s uk-width-1-1">
          <div class="uk-inline">
            <img src={specialSurfaces} className="uk-width-expand" alt="" />
            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
              <div className="uk-flex uk-flex-between">
                <h2 className="uk-h2 uk-padding-remove">SPECIAL SURFACES</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-1 uk-padding-small">
          <div className="uk-column-1-2@s">
            <div>
              <Link
                to={`${ssUrl}electrostatic-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Electrostatic Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}post-forming`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Post Forming Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}synchro-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Synchro Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}digital-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Digital Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}chalk/marker-board`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Chalk/Marker Board
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}unicore-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Unicore Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}mirror-shield`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Mirror Shield Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}magnetic-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Magnetic Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}metallic-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Metallic Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${ssUrl}flicker-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Flicker Laminates
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecialSurfaces
