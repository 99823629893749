import React from "react"
import compact from "../images/dnd/compact-laminates.jpg"
import { Link } from "gatsby"
import stylamIcon from "../images/dnd/stylam-icon.png"

function CompactLaminates() {
  const comlUrl = "/products/compact-laminates/"
  return (
    <div className="uk-container uk-margin-large">
      <div
        className="uk-card uk-card-default uk-padding-small uk-grid-collapse"
        data-uk-grid
      >
        <div className="uk-width-expand@s uk-width-1-1">
          <div class="uk-inline">
            <img src={compact} className="uk-width-expand" alt="" />
            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
              <div className="uk-flex uk-flex-between">
                <h2 className="uk-h2 uk-padding-remove">COMPACT LAMINATES</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-3@s uk-width-1-1 uk-padding-small">
          <div>
            <div>
              <Link
                to={`${comlUrl}cuboid-lockers`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Cuboid-Rest Room Cubicles & Lockers
              </Link>
            </div>
            <div>
              <Link
                to={`${comlUrl}fascia-exterior-cladding`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Fascia-Exterior Cladding
              </Link>
            </div>
            <div>
              <Link
                to={`${comlUrl}industrial-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Industrial Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${comlUrl}chemical-resistant-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Chemical Resistant Laminates
              </Link>
            </div>
            <div>
              <Link
                to={`${comlUrl}fire-grade-laminates`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Fire Retardant Grade Laminates
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompactLaminates
