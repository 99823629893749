import React from "react"
import { Link } from "gatsby"
import solidSurface from "../images/dnd/solid-surface.jpg"
import stylamIcon from "../images/dnd/stylam-icon.png"

function SolidSurface() {
  const solsUrl = "/products/solid-surface/"

  return (
    <div className="uk-container uk-margin-large">
      <div
        className="uk-card uk-card-default uk-padding-small uk-grid-collapse"
        data-uk-grid
      >
        <div className="uk-width-expand@s uk-width-1-1">
          <div class="uk-inline">
            <img src={solidSurface} className="uk-width-expand" alt="" />
            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
              <div className="uk-flex uk-flex-between">
                <h2 className="uk-h2 uk-padding-remove">SOLID SURFACE</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-1 uk-padding-small">
          <div className="">
            <div>
              <Link
                to={`${solsUrl}acrylic-solid-surface`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Acrylic Solid Surface - Granex
              </Link>
            </div>
            <div>
              <Link
                to={`${solsUrl}modified-solid-surface`}
                className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin"
              >
                <img
                  src={stylamIcon}
                  className="uk-margin-small-right"
                  alt=""
                />
                Modified Solid Surface - Marvela
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolidSurface
